import styled from 'styled-components';

export const Button = styled.button`
padding: 10px 16px;
background: white;
border-radius: 4px;
border: 1px solid #343a40;
color: #343a40;
font-size: 21px;
white-space: nowrap;
${props => props.block ? 'display: block; width: 100%' : ''};

&:hover{
  background: rebeccapurple;
  color: white;
}
`

export default Button;
